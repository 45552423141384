
<!-- 
   auto generated by order_menu.py. DO NOT EDIT.
   source: menu_type_0.py

   sample command:

   python3 order_menu.py [3,5,1,2] 0 > menu_type_0.html
-->
<ul>
  <li class="main-menu-22" *ngIf="show_cpsurl">
    <div [ngStyle]="{'background-color': backgroundColor}"></div>
    <a [ngStyle]="{'color': textColor}" target="_blank" [href]="cpsurl" routerLinkActive="router-link-active">
      {{ menuAlias?.getCpslink() ||  ('cpsurl.title' | translate) }}</a>
  </li>
  
  <li class="main-menu-23" *ngIf="show_links">
    <div [ngStyle]="{'background-color': backgroundColor}"></div>
    <a [ngStyle]="{'color': textColor}" [routerLink]="['/links']" routerLinkActive="router-link-active">
      {{ menuAlias?.getLink() ||  ('links.title' | translate) }}</a>
  </li>
  
  <li class="main-menu-24" *ngIf="show_links2">
    <div [ngStyle]="{'background-color': backgroundColor}"></div>
    <a [ngStyle]="{'color': textColor}" [routerLink]="['/links2']" routerLinkActive="router-link-active">
      {{ menuAlias?.getLink2() ||  ('links.title' | translate) }}</a>
  </li>
  
  <li class="main-menu-25" *ngIf="show_links3">
    <div [ngStyle]="{'background-color': backgroundColor}"></div>
    <a [ngStyle]="{'color': textColor}" [routerLink]="['/links3']" routerLinkActive="router-link-active">
      {{ menuAlias?.getLink3() ||  ('links.title' | translate) }}</a>
  </li>
  
  <li class="main-menu-26" *ngIf="show_links4">
    <div [ngStyle]="{'background-color': backgroundColor}"></div>
    <a [ngStyle]="{'color': textColor}" [routerLink]="['/links4']" routerLinkActive="router-link-active">
      {{ menuAlias?.getLink4() ||  ('links.title' | translate) }}</a>
  </li>
  
  <li class="main-menu-0" *ngIf="show_place">
    <div [ngStyle]="{'background-color': backgroundColor}"></div>
    <a [ngStyle]="{'color': textColor}" [routerLink]="['/places']" routerLinkActive="router-link-active">
      {{ menuAlias?.getPlace() ||  ('places.title' | translate) }}</a>
  </li>
  
  <li class="main-menu-7" *ngIf="show_news">
    <div [ngStyle]="{'background-color': backgroundColor}"></div>
    <a [ngStyle]="{'color': textColor}" [routerLink]="['/news']" routerLinkActive="router-link-active">
      {{ menuAlias?.getNews() ||  ('news.title' | translate) }}</a>
  
  <li class="main-menu-1" *ngIf="show_event">
    <div [ngStyle]="{'background-color': backgroundColor}"></div>
    <a [ngStyle]="{'color': textColor}" [routerLink]="['/events']" routerLinkActive="router-link-active">
      {{ menuAlias?.getEvent() ||  ('events.title' | translate) }}
    </a>
  </li>
  
  <li class="main-menu-2" *ngIf="show_shop">
    <div [ngStyle]="{'background-color': backgroundColor}"></div>
    <a [ngStyle]="{'color': textColor}" [routerLink]="['/shop']"
      routerLinkActive="router-link-active">
      <span [class]="'shop-menu' + (cartBadge ? '' : ' x')">
        {{ menuAlias?.getShop() ||  ('shop.title' | translate) }}
        <mat-icon [class]="'badge'" matBadgeColor="warn" matBadge="{{ cartBadge }}" *ngIf="cartBadge">shopping_cart</mat-icon>
      </span>
    </a>
  </li>
  
  <li class="main-menu-3" *ngIf="show_stream">
    <div [ngStyle]="{'background-color': backgroundColor}"></div>
    <a [ngStyle]="{'color': textColor}" [routerLink]="['/streams']" routerLinkActive="router-link-active">
      {{ menuAlias?.getStream() ||  ('streams.title' | translate) }}</a>
  </li>
  
  <li class="main-menu-4" *ngIf="show_stream2">
    <div [ngStyle]="{'background-color': backgroundColor}"></div>
    <a [ngStyle]="{'color': textColor}" [routerLink]="['/streams2']" routerLinkActive="router-link-active">
      {{ menuAlias?.getStream2() ||  ('streams.title' | translate) }}</a>
  </li>
  
  <li class="main-menu-5" *ngIf="show_stream3">
    <div [ngStyle]="{'background-color': backgroundColor}"></div>
    <a [ngStyle]="{'color': textColor}" [routerLink]="['/streams3']" routerLinkActive="router-link-active">
      {{ menuAlias?.getStream3() ||  ('streams.title' | translate) }}</a>
  </li>
  
  <li class="main-menu-6" *ngIf="show_stream4">
    <div [ngStyle]="{'background-color': backgroundColor}"></div>
    <a [ngStyle]="{'color': textColor}" [routerLink]="['/streams4']" routerLinkActive="router-link-active">
      {{ menuAlias?.getStream4() ||  ('streams.title' | translate) }}</a>
  </li>
  
  <li class="main-menu-8" *ngIf="show_news2">
    <div [ngStyle]="{'background-color': backgroundColor}"></div>
    <a [ngStyle]="{'color': textColor}" [routerLink]="['/news2']" routerLinkActive="router-link-active">
      {{ menuAlias?.getNews2() ||  ('news.title' | translate) }}</a>
  
  <li class="main-menu-9" *ngIf="show_news3">
    <div [ngStyle]="{'background-color': backgroundColor}"></div>
    <a [ngStyle]="{'color': textColor}" [routerLink]="['/news3']" routerLinkActive="router-link-active">
      {{ menuAlias?.getNews3() ||  ('news.title' | translate) }}</a>
  </li>
  
  <li class="main-menu-10" *ngIf="show_news4">
    <div [ngStyle]="{'background-color': backgroundColor}"></div>
    <a [ngStyle]="{'color': textColor}" [routerLink]="['/news4']" routerLinkActive="router-link-active">
      {{ menuAlias?.getNews4() ||  ('news.title' | translate) }}</a>
  </li>
  
  <li class="main-menu-11" *ngIf="show_competition">
    <div [ngStyle]="{'background-color': backgroundColor}"></div>
    <a [ngStyle]="{'color': textColor}" [routerLink]="['/competitions']" routerLinkActive="router-link-active">
      {{ menuAlias?.getCompetition() ||  ('competitions.title' | translate) }}</a>
  </li>
  
  <li class="main-menu-12" *ngIf="show_leader">
    <div [ngStyle]="{'background-color': backgroundColor}"></div>
    <a [ngStyle]="{'color': textColor}" [routerLink]="['/leaderboard']" routerLinkActive="router-link-active">
      {{ menuAlias?.getLeaderboard() ||  ('leaderboard.title' | translate) }}</a>
  </li>
  
  <li class="main-menu-13" *ngIf="show_member">
    <div [ngStyle]="{'background-color': backgroundColor}"></div>
    <a [ngStyle]="{'color': textColor}" [routerLink]="['/members']" routerLinkActive="router-link-active">{{ menuAlias?.getMember() || ('members.title' | translate) }}</a>

  </li>
  
  <li class="main-menu-14" *ngIf="show_forum">
    <div [ngStyle]="{'background-color': backgroundColor}"></div>
    <a [ngStyle]="{'color': textColor}" [routerLink]="['/forum']" routerLinkActive="router-link-active">
      <span [class]="'forum-menu' + (forumBadge ? '' : ' x')">
        {{ menuAlias?.getForum() ||  ('forum.title' | translate) }}
        <mat-icon [class]="'badge'" matBadgeColor="warn" matBadge="{{ forumBadge }}" *ngIf="forumBadge">chat</mat-icon>
      </span>
    </a>
  </li>
  
  <li class="main-menu-15" *ngIf="show_restaurant">
    <div [ngStyle]="{'background-color': backgroundColor}"></div>
    <a [ngStyle]="{'color': textColor}" (click)="open_restaurant()"
      routerLinkActive="router-link-active">
      <span [class]="'restaurant-menu' + (mealBadge ? '' : ' x')">
        {{ menuAlias?.getRestaurant() ||  ('menus.title' | translate) }}
        <mat-icon [class]="'badge'" matBadgeColor="warn" matBadge="{{ mealBadge }}" *ngIf="mealBadge">restaurant</mat-icon>
      </span>
    </a>
  </li>
  
  <li class="main-menu-16" *ngIf="show_benefit">
    <div [ngStyle]="{'background-color': backgroundColor}"></div>
    <a [ngStyle]="{'color': textColor}" [routerLink]="['/benefits']" routerLinkActive="router-link-active">
      {{ menuAlias?.getBenefit() ||  ('benefits.title' | translate) }}</a>
  </li>
  
  <li class="main-menu-17" *ngIf="show_album">
    <div [ngStyle]="{'background-color': backgroundColor}"></div>
    <a [ngStyle]="{'color': textColor}" [routerLink]="['/albums']" routerLinkActive="router-link-active">
      {{ menuAlias?.getAlbum() ||  ('albums.title' | translate) }}</a>
  </li>
  
  <li class="main-menu-18" *ngIf="show_shopprofile">
    <div [ngStyle]="{'background-color': backgroundColor}"></div>
    <a [ngStyle]="{'color': textColor}" [routerLink]="['/about']" routerLinkActive="router-link-active">
      {{ menuAlias?.getAboutus() ||  ('about.title' | translate) }}</a>
  </li>
  
  <li class="main-menu-20" *ngIf="show_document">
    <div [ngStyle]="{'background-color': backgroundColor}"></div>
    <a [ngStyle]="{'color': textColor}" [routerLink]="['/documents']" routerLinkActive="router-link-active">
      {{ menuAlias?.getDocument() ||  ('document.title' | translate) }}</a>
  </li>
  
  <li class="main-menu-32" *ngIf="show_news7">
    <div [ngStyle]="{'background-color': backgroundColor}"></div>
    <a [ngStyle]="{'color': textColor}" [routerLink]="['/news7']" routerLinkActive="router-link-active">
      {{ menuAlias?.getNews7() ||  ('news.title' | translate) }}</a>
  </li>
  
  <li class="main-menu-33" *ngIf="show_news8">
    <div [ngStyle]="{'background-color': backgroundColor}"></div>
    <a [ngStyle]="{'color': textColor}" [routerLink]="['/news8']" routerLinkActive="router-link-active">
      {{ menuAlias?.getNews8() ||  ('news.title' | translate) }}</a>
  </li>
  
  <li class="main-menu-34" *ngIf="show_news9">
    <div [ngStyle]="{'background-color': backgroundColor}"></div>
    <a [ngStyle]="{'color': textColor}" [routerLink]="['/news9']" routerLinkActive="router-link-active">
      {{ menuAlias?.getNews9() ||  ('news.title' | translate) }}</a>
  </li>
  
  <li class="main-menu-28" *ngIf="show_golf_teaching_class">
    <div [ngStyle]="{'background-color': backgroundColor}"></div>
    <a [ngStyle]="{'color': textColor}" [routerLink]="['/golf_class']" routerLinkActive="router-link-active">
      {{ menuAlias?.getGolfclasslink() ||  ('golf_class.title' | translate) }}</a>
  </li>
  
  <li class="main-menu-29" *ngIf="show_room_reservation">
    <div [ngStyle]="{'background-color': backgroundColor}"></div>
    <a [ngStyle]="{'color': textColor}" [routerLink]="['/guest/reservations']" routerLinkActive="router-link-active">
      {{ menuAlias?.getRoomreservation() ||  ('guest.menu-reservation' | translate) }}</a>
  </li>
  
  <li class="main-menu-30" *ngIf="show_news5">
    <div [ngStyle]="{'background-color': backgroundColor}"></div>
    <a [ngStyle]="{'color': textColor}" [routerLink]="['/news5']" routerLinkActive="router-link-active">
      {{ menuAlias?.getNews5() ||  ('news.title' | translate) }}</a>
  </li>
  
  <li class="main-menu-31" *ngIf="show_news6">
    <div [ngStyle]="{'background-color': backgroundColor}"></div>
    <a [ngStyle]="{'color': textColor}" [routerLink]="['/news6']" routerLinkActive="router-link-active">
      {{ menuAlias?.getNews6() ||  ('news.title' | translate) }}</a>
  </li>
  </ul>
<ul class="main-menu-27"  *ngIf="menus$ | async as menus ">
    <li *ngFor="let menu of menus">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <a [ngStyle]="{'color': textColor}" target="_blank" [href]="menu.getUrl()" routerLinkActive="router-link-active">
        {{ menu.getName() }}</a>
    </li>
</ul>

